



import { Abstract } from './Abstract'; 
  export class ShoppingRequisitionType extends Abstract 
  { 
  constructor(session) { 
  super('Shopping/ShoppingRequisitionType', session) ;

this.name = null; 

this.key_name = null; 


    }  
 } 
 
